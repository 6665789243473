import { useLocation, NavLink } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

// Components
import Logo from '../Logo';
/*import MenuBtn from '../MenuBtn';*/

// Styles
import './index.scss';

function Header(props) {

  const locationObj = useLocation();

  function menuBtnHandler() {
    if (locationObj.pathname.includes('/careers/')) {
      return <NavLink className="site-header--btn" to='/careers'>&larr; Careers</NavLink>;
    } else if (locationObj.pathname !== '/') {
      return <NavLink className="site-header--btn" to='/'>&larr; Go home</NavLink>;
    } else {
      /*return <MenuBtn menuOpen={props.menuOpen} setMenuOpen={props.setMenuOpen} />;*/
    }
  }

  function logoLinkHandler() {
    if (locationObj.pathname === '/') {
      return (
        <div className='site-header--logo' onClick={() => { scroll.scrollToTop({ duration: 500 }) }}>
          <Logo />
        </div>
      )
    } else {
      return (
        <NavLink className='site-header--logo' to='/'>
          <Logo />
        </NavLink>
      )
    }
  }

  return (
    <div className={`site-header ${props.headerStuck ? 'stuck' : ''}`}>
      {logoLinkHandler()}
      {menuBtnHandler()}
      <div className='site-header--menu' onClick={() => { scroll.scrollToTop({ duration: 500 }) }}>
        <a href='https://ir.hubsogroup.com/' target='_blank' rel='noreferrer nofollow'>Investor Relations</a>
      </div>
    </div>
  )
}

export default Header;
import { useLocation, NavLink } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

// Styles
import './index.scss';

// Compontents
import Logo from '../Logo';

// Icons
import IconInstagramOutline from '../../assets/icon-instagram-outline.svg';
import IconLinkedInOutline from '../../assets/icon-linkedin-outline.svg';

function Footer() {

  const locationObj = useLocation();

  function logoLinkHandler() {
    if (locationObj.pathname === '/') {
      return (
        <div className='footer-logo' onClick={() => { scroll.scrollToTop({ duration: 500 }) }}>
          <Logo />
        </div>
      )
    } else {
      return (
        <NavLink className='footer-logo' to='/'>
          <Logo />
        </NavLink>
      )
    }
  }

  return (
    <footer className='site-footer'>
      <div className='container'>
        <div className='footer-content'>
          <div className='footer-copyright'>
            {logoLinkHandler()}
            <span>© HUBSO AB, {new Date().getFullYear()}</span>
          </div>
          <div className='footer-address'>
            <a href='https://goo.gl/maps/VsXBwo3ndaEAYeyc9' target='_blank' rel='noreferrer nofollow'>
              <address>
                <strong>HUBSO AB</strong><br />
                Stora Nygatan 41<br />
                111 27, Stockholm<br />
                SWEDEN
              </address>
            </a>
          </div>
          <div className='footer-socials'>
            <a href='https://www.instagram.com/hubso_com/' target='_blank' rel='noreferrer nofollow'><img className='icon' src={IconInstagramOutline} alt='Instagram' /></a>
            <a href='https://www.linkedin.com/company/hubso/' target='_blank' rel='noreferrer nofollow'><img className='icon' src={IconLinkedInOutline} alt='LinkedIn' /></a>
          </div>
          <div className='footer-menu'>
            <ul>
              <li><a href='https://ir.hubsogroup.com/' target='_blank' rel='noreferrer nofollow'>Investor Relations</a></li>
              <li><a href='https://www.linkedin.com/company/hubso/jobs/' target='_blank' rel='noreferrer nofollow'>Careers</a></li>
              <li><a href='https://ir.hubsogroup.com/press-releases' target='_blank' rel='noreferrer nofollow'>Press</a></li>
              <li><NavLink to='/ethical-guidelines'>Ethical Guidelines</NavLink></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
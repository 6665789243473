// Styles
import './index.scss';

function Vision() {
  return (
    <section id='vision'>
      <div className='section-content'>
        <h4 className='section-title'>
          Our vision
        </h4>
        <p className='section-body'>
          We believe in authentic and meaningful brands. We want to highlight the strength and uniqueness of each influencer, bringing those qualities to life and creating a brand that truly represents them. This is what we call true branding, the core of our business. With this as our foundation we're building sales driven businesses/brands that can grow and last over time.
        </p>
      </div>
      <div className='overlay' />
    </section>
  )
}

export default Vision;
const SwiperData = [
  {
    'title': 'Sanne Alexandra',
    'url': 'sannealexandra.com',
    'bg': 'sanne-header-1.jpg',
    'bg_alt': '',
    'bg_position': 'center',
    'color': 'light',
    'overlay': true
  },
  {
    'title': 'Le Capsole',
    'url': 'lecapsole.com',
    'bg': 'lecapheader.jpg',
    'bg_position': 'center',
    'bg_alt': '',
    'color': 'light',
    'overlay': true
  },
  
  {
    'title': 'LXA',
    'url': 'lxathelabel.com',
    'bg': 'lxaheader1.jpg',
    'bg_position': 'center',
    'bg_alt': '',
    'color': 'light',
    'overlay': true
  },
  {
    'title': 'VIQ Beauty',
    'url': 'viqbeauty.com',
    'bg': 'slide_bg_6.jpg',
    'bg_position': 'center',
    'bg_alt': '',
    'color': 'light',
    'overlay': true
  }
];

export default SwiperData;
import { useState, useEffect } from 'react';
import sanityClient from '../../client';
import { motion } from 'framer-motion';
import { useSpring, animated, easings } from 'react-spring';
import JobCard from '../JobCard.js';
// Styles
import './index.scss';

export default function Jobs() {
  /**
   * STATE
   */
  const [jobs, setJobs] = useState(null);

  /**
   *  Fetch jobs from Sanity Client
   */
  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "job"] | order(publishedDate desc) {
        title,
        slug,
        publishedDate,
        location
      }`
      )
      .then((data) => setJobs(data))
      .catch(console.error);
  }, [])

  /**
   * Framer Motion stuff
   */
  const jobsVariants = {
    initial: {
      opacity: 0
    },
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: .15,
        delayChildren: .5
      },
    }
  }

  /**
   * Animations
   */
  const titleAnim = useSpring({
    from: {
      y: '50%',
      opacity: 0
    },
    to: {
      y: '0%',
      opacity: 1
    },
    config: { 
      duration: 1000,
      easing: easings.easeOutExpo 
    },
    delay: 500
  });

  return (
    <>
      <div className='jobs'>
        <div className='container'>
          <animated.h2 className='title h3' style={titleAnim}>
            Available positions
          </animated.h2>
          {jobs &&
            <motion.div className='job-list'
              initial='initial'
              animate='animate'
              variants={jobsVariants}
            >
              {jobs.map((job, index) => (
                <JobCard job={job} key={index} />
              ))}
            </motion.div>
          }
        </div>
      </div>
    </>
  )
}

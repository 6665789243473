const MenuItems = [
  {
    title: 'Our ecosystem',
    link: 'ecosystem',
  },
  {
    title: 'Influencers',
    link: 'influencers',
  },
  {
    title: 'Our vision',
    link: 'vision',
  },
  {
    title: 'Contact',
    link: 'contact',
  },
];

export default MenuItems;
// Styles
import './index.scss';

// Icons
import IconInstagram from '../../assets/icon-instagram.svg';
import IconContact from '../../assets/icon-contact.svg';
import IconPhone from '../../assets/icon-phone.svg';
import IconAddress from '../../assets/icon-address.svg';

function Contact() {
  return (
    <section id='contact'>
      <div className='section-content'>
        <h4 className='section-title'>Contact</h4>
        <ul className='contact-content'>
          <li className='email'>
            <a className='flex align-center' href='mailto:info@hubso.com'>
              <img className='icon' src={IconContact} alt='Contact' style={{ marginTop: '-3px' }} />
              <span>info@hubso.com</span>
            </a>
          </li>
          <li className='phone'>
            <a className='flex align-center' href='tel:+46(0)793349620'>
              <img className='icon' src={IconPhone} alt='Telephone: ' />
              <div>
                <strong>Customer support / brands</strong><br />
                <span>+46 (0) 79 334 96 20</span>
              </div>
            </a>
          </li>
          <li className='phone'>
            <a className='flex align-center' href='tel:+46(0)812153890'>
              <img className='icon' src={IconPhone} alt='Telephone: ' />
              <div>
                <strong>Company</strong><br />
                <span>+46 (0) 8 - 121 538 90</span>
              </div>
            </a>
          </li>
          <li className='instagram'>
            <a className='flex align-center' href='https://www.instagram.com/hubso_com' target='_blank' rel='noreferrer nofollow'>
              <img className='icon' src={IconInstagram} alt='Instagram' />
              <span>@hubso_com</span>
            </a>
          </li>
          <li className='address'>
            <a className='flex align-center' href='https://goo.gl/maps/VsXBwo3ndaEAYeyc9' target='_blank' rel='noreferrer nofollow'>
              <img className='icon' src={IconAddress} alt='Address: ' />
              <span>
                <strong>HUBSO AB</strong><br />
                Stora Nygatan 41, 111 27 Stockholm, SWEDEN
              </span>
            </a>
          </li>
        </ul>
      </div>
      <div className='overlay' />
    </section>
  );
}

export default Contact;
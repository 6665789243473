import { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import moment from 'moment';
import { easings } from 'react-spring';

export default function JobCard({ job }) {

  /**
   * STATE
   */
  const [isCardHovered, setCardHovered] = useState(0);

  /**
   * Framer Motion stuff
   */
  const jobVariants = {
    initial: {
      opacity: 0,
      y: '10%'
    },
    animate: {
      opacity: 1,
      y: '0%',
      transition: {
        ease: easings.easeOutExpo,
        duration: .66,
      }
    }
  }

  return (
    <motion.div className='job-card'
      onMouseEnter={() => setCardHovered(true)}
      onMouseLeave={() => setCardHovered(false)}
      variants={jobVariants}
      animate={{ scale: isCardHovered ? 1.025 : 1, transition: { duration: .1 } }}
    >
      <Link to={'/careers/' + job.slug.current}>
        <motion.div
          animate={{ opacity: isCardHovered ? .5 : 1, transition: { duration: .2 } }}
        >
          <div style={{
            marginBottom: '.25rem',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            columnGap: '1rem',
            alignItems: 'baseline',
          }}
          >
            <h4 className='job-title'>{job.title}</h4>
            <span className='job-location'>{job.location}</span>
          </div>
          <div className='job-meta' style={{ marginBottom: '.25rem' }}>
            <span className='job-published'>Posted:&nbsp;{moment(job.publishedDate).format('MMM Do, YYYY')}</span>
          </div>
          <motion.span className='job-readmore'>Read more &rarr;</motion.span>
        </motion.div>
      </Link>
    </motion.div>
  )
}

import { useEffect } from 'react';
import Helmet from 'react-helmet';

// Components
import HeroSlider from '../../components/HeroSlider';
import Manifest from '../../components/Manifest';
import Ecosystem from '../../components/Ecosystem';
import Influencers from '../../components/Influencers';
import Vision from '../../components/Vision';
import Contact from '../../components/Contact';

function Home() {
  /**
   * Add component className to body
   */
  useEffect(() => {
    document.body.classList.add('home');
    return () => {
      document.body.classList.remove('home');
    }
  })

  const metaTitle = 'HUBSO — Transforming creativity into desire';
  const metaDesc = 'HUBSO is a one-stop hub for influencers who want to establish their own brand and get a seamless procedure regarding production, marketing and e-commerce.';

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDesc} />
        <meta name="twitter:title" content={metaTitle} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:site_name" content={metaTitle} />
        <meta property="og:url" content="hubso.com" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
      </Helmet>
      <HeroSlider />
      <Manifest />
      <div className='eco-inf'>
        <Ecosystem />
        <Influencers />
      </div>
      <div className='vis-con'>
        <Vision />
        <Contact />
      </div>
    </>
  )
}

export default Home;
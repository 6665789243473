// Components
import InfluencerItem from '../InfluencerItem';

// Styles
import './index.scss';

// Data
import InfluencerData from '../../data/InfluencerData';

function Influencers() {
  return (
    <section id='influencers'>
      <div className='section-title-wrapper'>
        <h4 className='section-title'>Our Creators</h4>
      </div>
      <div className='influencer-grid'>
        {InfluencerData.map((influencer, index) => {
          return (
            <InfluencerItem influencer={influencer} key={index} />
          )
        })}
      </div>
    </section>
  )
}

export default Influencers;

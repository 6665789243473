// Styles
import './index.scss';

function Ecosystem() {
  return (
    <section id='ecosystem'>
      <div className='section-content'>
        <h4 className='section-title'>
          Our ecosystem
        </h4>
        <div className='ecosystem-graphic'>
          <img
            src={require('../../assets/hubso_ecosystem.png')}
            alt='HUBSO ecosystem'
          />
        </div>
      </div>
      <div className='overlay'></div>
    </section>
  )
}

export default Ecosystem;
import { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './index.scss';

// Import slide data
import SwiperData from '../../data/SwiperData';

function HeroSlider() {
  // const [logoColor, setLogoColor] = useState(0);

  return (
    <div id="heroSwiper">
      <h3 className='swiper-tagline'>Transforming creativity into desire.</h3>
      <Swiper
        modules={[Navigation, Pagination, A11y, Autoplay]}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false
        }}
        speed={1500}
        spaceBetween={0}
        slidesPerView={1}
        navigation
        draggable
        grabCursor
        autoHeight
        pagination={{ clickable: true }}
      >
        {SwiperData.map((slide, index) => {
          return (
            <SwiperSlide key={index}>
              <div className='slide-content' data-color={slide.color}>
                <h2 className='slide-title'>{slide.title}</h2>
                <a className='slide-url' href={'https://' + slide.url} target='_blank' rel='noreferrer'>{slide.url}</a>
              </div>
              {slide.overlay && <div className='slide-overlay' data-color={slide.color} /> }
              <img className='slide-bg' src={require('../../assets/' + slide.bg)} alt={slide.bg_alt} style={{ objectPosition: slide.bg_position }} />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default HeroSlider;
const InfluencerData = [
  {
    "name": "VIQ Beauty",
    "url": "viqbeauty.com",
    "img": "influencer_qvist.jpg",
  },
  {
    "name": "Le Capsole",
    "url": "lecapsole.com",
    "img": "emi1.jpg"
  },
  {
    "name": "Sanne Alexandra",
    "url": "sannealexandra.com",
    "img": "sanne1.jpg",
  },
  {
    "name": "LXA the label",
    "url": "lxathelabel.com",
    "img": "linn.jpg"
  }
];

export default InfluencerData;
import Helmet from 'react-helmet';
import { useSpring, animated, easings } from 'react-spring';

// Styles
import './index.scss';

// Components
import Jobs from '../../components/Jobs';
import { useEffect } from 'react';

export default function Careers() {

  /**
   * Add component className to body
   */
  useEffect(() => {
    document.body.classList.add('careers');
    return () => {
      document.body.classList.remove('careers');
    }
  })

  /**
   * Animations
   */
  const bannerAnim = useSpring({
    from: {
      y: '-100%',
      height: 100,
    },
    to: {
      y: '0%',
      height: 300
    },
    config: {
      duration: 1000,
      easing: easings.easeOutExpo
    }
  })

  const metaTitle = 'Careers';

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="twitter:title" content={metaTitle} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:site_name" content={metaTitle} />
        <meta property="og:url" content="hubso.com" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div id='careers'>
        <animated.div className='page-header' style={bannerAnim}>
          <h1 className='page-title'>Careers</h1>
        </animated.div>
        <div className='page-content'>
          <Jobs />
        </div>
      </div>
    </>
  )
}

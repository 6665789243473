import { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { PortableText } from '@portabletext/react';
import sanityClient from '../../client';
import moment from 'moment';

// Styles
import './index.scss';

export default function Job() {

  /**
   * Add component className to body
   */
  useEffect(() => {
    document.body.classList.add('job');
    return () => {
      document.body.classList.remove('job');
    }
  })

  const [jobData, setJobData] = useState(null);
  const { slug } = useParams();

  /**
   * Fetch job data from Sanity
   */
  useEffect(() => {
    sanityClient
      .fetch(`*[slug.current == $slug]{
        title,
        slug,
        publishedDate,
        location,
        portableText
      }`,
        { slug }
      )
      .then((data) => setJobData(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!jobData) return <div></div>;

  /**
   * Framer Motion stuff
   */
  const pageVariants = {
    initial: {
      opacity: 1
    },
    exit: {
      opacity: 0,
      transition: {
        duration: .2
      }
    }
  }
  const containerVariants = {
    initial: {

    },
    animate: {
      transition: {
        staggerChildren: .2,
        delayChildren: .2
      }
    }
  }
  const fadeUp = {
    initial: {
      opacity: 0,
      y: 32
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        ease: [.1, .3, .3, 1],
        duration: .66,
      }
    }
  }

  const metaTitle = 'Job opening: ' + jobData.title + ' | HUBSO';
  const metaDesc = 'We are looking for someone who wants to be a part of developing the future of e-commerce and brand building on an international scale.';

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDesc} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDesc} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDesc} />
        <meta property="og:site_name" content="HUBSO" />
        <meta property="og:url" content="hubso.com" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
      </Helmet>
      <motion.div className='job'
        initial='initial'
        exit='exit'
        variants={pageVariants}
      >
        <motion.div className='container small'
          initial='initial'
          animate='animate'
          variants={containerVariants}
        >
          <motion.h3
            variants={fadeUp}
          >
            {jobData.title}
          </motion.h3>
          <motion.div variants={fadeUp} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '.5rem' }}>
            <span className='job-location'>{jobData.location}</span>
            <span>&mdash;</span>
            <span className='job-published'>Posted:&nbsp;{moment(jobData.publishedDate).format('MMM Do, YYYY')}</span>
          </motion.div>
          <motion.div variants={fadeUp}>
            <PortableText value={jobData.portableText} variants={fadeUp} />
          </motion.div>
        </motion.div>
      </motion.div>
    </>
  )
}

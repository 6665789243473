function InfluencerItem(props) {
  return (
    <div className='influencer-grid--item'>
      <div className='influencer-grid--item-content'>
        <h5 className='title'>{props.influencer.name}</h5>
        <a className='btn' href={'https://' + props.influencer.url} target='_blank' rel='noreferrer nofollow'>
          Explore
        </a>
      </div>
      <img className='influencer-grid--item-bg' src={require('../../assets/' + props.influencer.img)} alt='' />
      <div className='overlay'></div>
    </div>
  )
}

export default InfluencerItem;
// Styles
import './index.scss';

function Manifest() {
  return (
    <section id='ourManifest' className='our-manifest'>
      <div className='container'>
        <div className='section-content'>
          <h3 className='h5'>
            Our business is to transform creatives into entrepreneurs, by developing
            authentic<br/>e-commerce brands. We are the solid partner who handles the
            infrastructure of production, branding and e-commerce solutions.
          </h3>
          <h3 className='h5' style={{ marginTop: 0 }}>
            We build success stories with our
            dedicated team together with collected
            data as a backbone. We work always as a team, because none of us is as good as <span className='emphasize'><span>all of us.</span></span>
          </h3>
        </div>
      </div>
    </section>
  )
}

export default Manifest;
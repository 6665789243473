import { useEffect } from 'react';
import { Link } from 'react-router-dom';

// Styles
import './index.scss';

export default function NotFound() {
  /**
   * Add component className to body
   */
  useEffect(() => {
    document.body.classList.add('not-found');
    return () => {
      document.body.classList.remove('not-found');
    }
  })

  return (
    <div className='container small' style={{ margin: '8rem auto' }}>
      <h1>404 - Not Found</h1>
      <p>Seems like you've lost your way.</p>
      <Link to='/'>&larr; Go Home</Link>
    </div>
  )
}

import { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { motion } from 'framer-motion';

// Styles
import './index.scss';

// Data
import MenuItems from '../../data/MenuItems';

function Menu({ menuOpen, setMenuOpen }) {
  /**
   * State
   */
  const [targetElement, setTargetElement] = useState(0);

  /**
   * Menu functionality
   */
  const menuRef = useRef();

  useEffect(() => {
    setTargetElement(menuRef);
  }, []);

  useEffect(() => {
    if (targetElement) {
      if (menuOpen) {
        disableBodyScroll(targetElement);
      } else {
        enableBodyScroll(targetElement);
      }
    }
  }, [targetElement, menuOpen]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  /**
   * Motion variants
   */

  const menuListVariants = {
    initial: {
      opacity: 0
    },
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: .1,
        delayChildren: .3
      }
    }
  }
  const subMenuListVariants = {
    initial: {
      opacity: 0
    },
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: .1,
        delayChildren: .8
      }
    }
  }
  const menuItemVariants = {
    initial: {
      opacity: 0,
      y: '10%'
    },
    animate: {
      opacity: 1,
      y: '0%',
      transition: {
        ease: [.1, .3, .3, 1],
        duration: .66,
      }
    }
  }

  return (
    <div className='menu-wrapper'>
      {menuOpen && (
        <aside id='siteMenu' ref={menuRef}>
          <button className='close-menu' onClick={() => menuOpen ? setMenuOpen(false) : setMenuOpen(true)}>
            <span />
            <span />
          </button>
          {MenuItems &&
            <motion.ul className='menu'
              initial='initial'
              animate='animate'
              variants={menuListVariants}
            >
              {MenuItems.map((menuItem, index) => {
                return (
                  <motion.li className='menu-item'
                    key={index}
                    variants={menuItemVariants}
                  >
                    <Link to={'/#' + menuItem.link}
                      className='anchor-link'
                      onClick={() => setMenuOpen(false)}
                    >
                      {menuItem.title}
                    </Link>
                  </motion.li>
                )
              })}
            </motion.ul>
          }
          <motion.ul className='sub-menu'
            initial='initial'
            animate='animate'
            variants={subMenuListVariants}
          >
            <motion.li className='submenu-item'
              variants={menuItemVariants}
            >
              <NavLink to='/careers' onClick={() => setMenuOpen(false)}>Careers</NavLink>
            </motion.li>
            <motion.li className='submenu-item'
              variants={menuItemVariants}
            >
              <a href='https://ir.hubsogroup.com/' target='_blank' rel='noreferrer nofollow' onClick={() => setMenuOpen(false)}>Investor Relations</a>
            </motion.li>
            <motion.li className='submenu-item'
              variants={menuItemVariants}
            >
              <a href='https://hubsogroup.com/press' target='_blank' rel='noreferrer nofollow' onClick={() => setMenuOpen(false)}>Press</a>
            </motion.li>
          </motion.ul>
        </aside>
      )}
    </div>
  )
}

export default Menu;
import { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

// Helpers
import { getScrollbarWidth, useScrollPosition } from './state/helpers';

// Styles
import './App.scss';

// Components
import Header from './components/Header';
import Footer from './components/Footer';
import Menu from './components/Menu';

// Routes
import Home from './routes/Home';
import Careers from './routes/Careers';
import Job from './components/Job';
import NotFound from './components/NotFound';
import Ethical from './routes/Ethical';

function App({ hideLoader }) {

  /**
   * Preloader
   */
  useEffect(
    hideLoader, [hideLoader]
  );

  const location = useLocation();

  /**
   * STATE
   */
  const [menuOpen, setMenuOpen] = useState(false);
  const [headerStuck, setHeaderStuck] = useState(false);

  /**
   * Stick the header when scrolling down
   */
  const scrollPos = useScrollPosition();
  useEffect(() => {
    scrollPos > 0 ? setHeaderStuck(true) : setHeaderStuck(false);
  }, [scrollPos])

  /**
   * Do some stuff if menu is open
   */
  useEffect(() => {
    let scrollbarWidth = getScrollbarWidth() + 'px';
    if (menuOpen) {
      document.body.style.marginRight = scrollbarWidth;
      document.body.classList.add('menu-open');
    } else {
      document.body.style.marginRight = '';
      document.body.classList.remove('menu-open');
    }
  }, [menuOpen]);

  return (
    <>
      <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} headerStuck={headerStuck} />
      <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <main className='site-main'>
        <Routes location={location} key={location.pathname}>
          <Route element={<Home />} path='/' />
          <Route element={<Careers />} path='/careers' />
          <Route element={<Job />} path='/careers/:slug' />
          <Route element={<NotFound />} path='*' />
          <Route element={<Ethical />} path='/ethical-guidelines' />
        </Routes>
      </main>
      <Footer />
    </>
  )
}

export default App;

import { useSpring, animated, easings } from 'react-spring';

// Styles
import './index.scss';

// Components
import { useEffect } from 'react';

export default function Careers() {

  /**
   * Add component className to body
   */
  useEffect(() => {
    document.body.classList.add('careers');
    return () => {
      document.body.classList.remove('careers');
    }
  })

  /**
   * Animations
   */
  const bannerAnim = useSpring({
    from: {
      y: '-100%',
      height: 100,
    },
    to: {
      y: '0%',
      height: 300
    },
    config: {
      duration: 1000,
      easing: easings.easeOutExpo
    }
  })

  

  return (
    <>
      
      <div id='ethical'>
        <animated.div className='page-header' style={bannerAnim}>
          <h1 className='page-title'>Ethical Guidelines</h1>
        </animated.div>
        <section id='ethical-content'>
          <div className='container'>
            <div className='section-content'>
              <p id="first-row-ethical">Hubso AB Code of Conduct and Ethical Guidelines</p>
              <h3 className='h3'>
              Introduction
              </h3>
              <p>At Hubso AB, we are committed to conducting our business with the highest standards of ethics, integrity, and professionalism. Our success is built on trust, and we believe that trust is earned through transparent and ethical behavior. This Code of Conduct and Ethical Guidelines outlines the principles and expectations that all employees must adhere to in their daily work.</p>
              <h3>1. Honesty and Integrity</h3>
              <p>We conduct ourselves with honesty and integrity in all interactions, both within the company and with our customers, partners, and stakeholders. This includes:<br/>
                - Truthfulness and accuracy in all communications.<br/>
                - Avoiding conflicts of interest that may compromise our objectivity or judgment.<br/>
                - Reporting any unethical behavior or violations of this code.
              </p>
              <h3>2. Respect and Inclusion</h3>
              <p>We value diversity and inclusion and treat all individuals with respect and fairness. This includes:<br/>
                - Creating a workplace that is free from discrimination, harassment, and bullying.<br/>
                - Respecting differences in culture, background, and perspectives.<br/>
                - Promoting a culture of collaboration and teamwork.
              </p>
              <h3>3. Confidentiality</h3>
              <p>We safeguard the confidentiality of sensitive company and customer information. This includes<br/>
                - Protecting trade secrets, proprietary information, and customer data.<br/>
                - Not disclosing confidential information to unauthorized individuals.<br/>
                - Using caution when discussing company matters in public or on social media.
              </p>
              <h3>4. Compliance with Laws and Regulations</h3>
              <p>For all external vendors and third-party production sites, we have executed purchase agreements, factory information documents, and codes of conduct.<br/><br/>
                  We comply with all applicable laws, regulations, and industry standards. This includes:<br/>
                  - Following local, national, and international laws relevant to our business.<br/>
                  - Complying with industry-specific regulations and standards.<br/>
                  - Seeking guidance from legal experts when necessary.
              </p>
              <h3>5. Customer Focus</h3>
              <p>Our customers are our top priority. This includes:<br/>
                - Providing excellent customer service.<br/>
                - Resolving customer issues promptly and professionally.<br/>
                - Avoiding any deceptive or unfair practices in our interactions with customers.
              </p>
              <h3>6. Environmental and Social Responsibility</h3>
              <p>We are committed to minimizing our environmental impact and contributing positively to our community. This includes:<br/>
                - Complying with environmental regulations and striving to reduce our carbon footprint.<br/>
                - Supporting social responsibility initiatives that benefit our community.<br/>
                - Encouraging responsible consumption and production practices.
              </p>
              <h3>7. Reporting Violations</h3>
              <p>If you witness or suspect a violation of this Code of Conduct, it is your responsibility to report it promptly to your supervisor or through our confidential reporting mechanism.
              </p>
              <h3>Consequences of Non-Compliance</h3>
              <p>Violations of this Code of Conduct may result in disciplinary action, up to and including termination of employment.
              </p>
              <h3>Conclusion</h3>
              <p>At Hubso AB, we believe that ethical behavior is the foundation of our success. We are committed to upholding these principles and expect every employee to do the same. By adhering to this Code of Conduct, we contribute to a positive workplace culture and maintain the trust of our customers and partners.<br/><br/>
              This Code of Conduct is a living document and may be updated periodically to reflect changes in our business environment or legal requirements. Employees are encouraged to revisit it regularly and seek guidance when in doubt.
              </p>
              <p id='sign-last-row'>
                  Hubso AB<br/>
                  3/10-23
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
